import Content from '../../../components/Content/Content';
import './OtherOfferings.css';

const MaintenancePage = () => {

	return (
		<Content headerFooterMenu="mountain-west">
			<div className="maintain_container">
				<p className='maintain_title'>
					<img height={100} width={100} src="/images/under.png"></img>
					<br />
					<br />
					Pardon our dust as Client Connect is currently undergoing scheduled maintenance and updates. Sorry for any inconvenience, but we should be back up shortly.
					<br />
					<br />
					For inquiries, please reach out to your agent or contact customer service at 866-882-9871.
				</p>
			</div>

		</Content>
	);
};
export default MaintenancePage;
